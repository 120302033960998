import React from 'react'
import { Link } from 'react-router-dom'
import './style.css'

const Recommendation = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
      
    const serviceWrap = [
        {
           sIcon:'fi flaticon-tray',
           title:"Delicious Food",
           des:'Best street food, and cheap eats', 
           link:"/service-single"
        },
        {
           sIcon:'fi flaticon-pool',
           title:"Accommodation",
           des:'Cheap lodging and hostels',           
           link:"/service-single"
        },
        {
           sIcon:'fi flaticon-herbal',
           title:"Relaxation",
           des:'Relaxing spas, wellness and yoga etc.',
           link:"/service-single"
        },
        {
           sIcon:'fi flaticon-bowling',
           title:"Activities",
           des:'Activities and things to do in the area, hiking, water sports, etc.',
           link:"/service-single"
        },
        {
           sIcon:'fi flaticon-taxi',
           title:"Local Transport",
           des:'Guide to get around locally, public transport, taxi, etc.',
           link:"/service-single"
        },
        {
           sIcon:'fi flaticon-star-1',
           title:"Sightseeing",
           des:'Main tourist attractions, points of interest and view points',
           link:"/service-single"
        },
    ]

    return(
        <div className="service-area section-padding">
            <div className="container">
                <div className="col-12">
                    <div className="wpo-section-title">
                        <span></span>
                        <h2>Our Recommendations</h2>
                    </div>
                </div>
                <div className="row">
                    {serviceWrap.map((service, sev) => (
                        <div className="col col-lg-4 col-md-6 custom-grid col-12" key={sev}>
                            <div className="service-item">
                                <div className="service-icon">
                                    <i className={service.sIcon}></i>
                                </div>
                                <div className="service-text">
                                    <h2><Link onClick={ClickHandler} to={service.link}>{service.title}</Link></h2>
                                    <p>{service.des}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Recommendation;