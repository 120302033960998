import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import Hero2 from '../../components/hero2'
//import RoomSection from '../../components/RoomSection'
import BlogSection from '../../components/BlogSection'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import hero1 from '../../images/slider/cherdchai_banner.png'
import hero2 from '../../images/slider/cherdchai_banner.png'
import hero3 from '../../images/slider/cherdchai_banner.png'
import DestinationCc from '../../components/Destination_cherdchai';

//removed room section
const Cherdchai =() => {
    return(
        <Fragment>
            <Navbar/>
            <Hero2 heroClass={'hero-style-2'} heroImg1={hero1} heroImg2={hero2} heroImg3={hero3}/>
            <DestinationCc/>
            <BlogSection/>
            <Footer/> 
            <Scrollbar/>
        </Fragment>
    )
};
export default Cherdchai;