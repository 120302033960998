import React from 'react'
import dimg from '../../../images/service-single/img-1.jpg'


const Description = (props) => {

    return(
        <div className="service-single-des">
            <div className="service-single-img">
                <img src={dimg} alt="" />
            </div>
            <h2>OG Tix</h2>
            <p>Book now to get the best price the easiest way. </p>
            <p>We have decades of experience in Thailand.</p>                   
    </div>
    )
}

export default Description;